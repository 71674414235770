.image-base {
    margin: 8px;
}

.square {
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
}

.circle {
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.circle-strans {
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

@media (max-width: 768px) {
    .image-base[aria-description="logo"] {
        width: 48px;
        height: 48px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .image-base[aria-description="logo"] {
        width: 58px;
        height: 58px;
    }
}

@media (min-width: 1025px) {
    .image-base[aria-description="logo"] {
        width: 72px;
        height: 72px;
    }
}
